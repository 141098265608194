import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy';
import { FullScreenLayout } from '@truvhq/ui';
import { Redirect, generatePath, useLocation } from 'react-router-dom';

import { AuthGuard } from 'components/AuthGuard';
import { GuestGuard } from 'components/GuestGuard';
import { PermissionGuard } from 'components/PermissionGuard';
import { useHasHubspotFeature } from 'hooks/features';
import { TwoPaneLayout } from 'layouts';
import { useAuth } from 'shared/lib/auth/useAuth';
import { urls } from 'shared/urls';
import type { Permissions } from 'types/permissions';
import { TeamTab } from 'types/team';

import type { Routes } from './RenderRoutes';
import { RenderRoutes } from './RenderRoutes';

const AllUsersPage = reactLazyWithRetry(() => import('views/pages/AllUsersPage'));
const UserInformationPage = reactLazyWithRetry(() => import('views/pages/UserInformationPage'));
const UsersPage = reactLazyWithRetry(() => import('views/pages/UsersPage'));
const LoginView = reactLazyWithRetry(() => import('views/auth/LoginView'));
const SignUpView = reactLazyWithRetry(() => import('views/auth/SignUpView'));
const HomePage = reactLazyWithRetry(() => import('views/pages/HomePage'));
const UsageView = reactLazyWithRetry(() => import('views/pages/UsageView'));
const PerformanceReportsPage = reactLazyWithRetry(() => import('views/pages/PerformanceReportsPage'));
const TeamPerformancePage = reactLazyWithRetry(() => import('views/pages/TeamPerformancePage'));
const EmulatorPage = reactLazyWithRetry(() => import('views/pages/EmulatorView'));
const TasksPage = reactLazyWithRetry(() => import('views/pages/TasksPage'));
const LogsPage = reactLazyWithRetry(() => import('views/pages/LogsPage'));
const WebhooksView = reactLazyWithRetry(() => import('views/pages/WebhooksView'));
const WebhookView = reactLazyWithRetry(() => import('views/pages/WebhookView'));
const SystemStatusPage = reactLazyWithRetry(() => import('views/pages/SystemStatusPage'));
const OrdersPage = reactLazyWithRetry(() => import('views/pages/OrdersPage'));
const OrderInformation = reactLazyWithRetry(() => import('views/pages/OrderInformationPage'));
const CreateOrderPage = reactLazyWithRetry(() => import('views/pages/CreateOrderPage'));
const BatchOrdersPage = reactLazyWithRetry(() => import('views/pages/BatchOrdersPage'));
const CompanyPage = reactLazyWithRetry(() => import('views/pages/CompanyPage'));
const AddMemberPage = reactLazyWithRetry(() => import('views/pages/AddMemberPage'));
const TeamPage = reactLazyWithRetry(() => import('views/pages/TeamPage'));
const UserDetailsPage = reactLazyWithRetry(() => import('views/pages/UserDetailsPage'));
const NotFoundView = reactLazyWithRetry(() => import('views/errors/NotFoundView'));
const EditMemberPage = reactLazyWithRetry(() => import('views/pages/EditMemberPage'));
const ProfileView = reactLazyWithRetry(() => import('views/pages/ProfileView'));
const KeysPage = reactLazyWithRetry(() => import('views/account/AccountView/Keys'));
const GenerateKeysPage = reactLazyWithRetry(() => import('views/account/AccountView/GenerateKeys'));
const FieldsCustomizationPage = reactLazyWithRetry(() => import('views/pages/FieldsCustomizationPage'));
const CoveragePage = reactLazyWithRetry(() => import('views/pages/CoveragePage'));
const SupportTicketsPage = reactLazyWithRetry(() => import('views/pages/SupportTicketsPage'));
const SupportTicketCreatePage = reactLazyWithRetry(() => import('views/pages/TicketCreatePage'));
const SupportTicketCreatePageSelectCategory = reactLazyWithRetry(() => import('views/pages/SelectSubcategoryPage'));
const SupportTicketCreatePageFillDetails = reactLazyWithRetry(() => import('views/pages/TicketFillDetailsPage'));
const TicketInformationPage = reactLazyWithRetry(() => import('views/pages/TicketInformationPage'));
const CustomSettingsPage = reactLazyWithRetry(() => import('views/pages/CustomSettingsPage'));
const BrandingPage = reactLazyWithRetry(() => import('views/pages/BrandingCustomizationPage'));
const TemplatesPage = reactLazyWithRetry(() => import('views/pages/TemplatesPage'));
const TemplateCreatePage = reactLazyWithRetry(() => import('views/pages/TemplateCreatePage'));
const TemplateEditPage = reactLazyWithRetry(() => import('views/pages/TemplateEditPage'));
const TwilioIntegrationPage = reactLazyWithRetry(() => import('views/pages/TwilioIntegrationPage'));
const MarketplacePage = reactLazyWithRetry(() => import('views/pages/MarketplacePage'));
const ExternalIntegrationPage = reactLazyWithRetry(() => import('views/pages/ExternalIntegrationPage'));
const PlatformsPage = reactLazyWithRetry(() => import('views/pages/PlatformsPage'));
const VerificationPage = reactLazyWithRetry(() => import('views/pages/VerificationPage'));
const VerificationStatusPage = reactLazyWithRetry(() => import('views/pages/VerificationStatusPage'));

type GetRoutesParams = {
  defaultRedirectUrl: string;
  permissions?: Permissions;
  isStaff: boolean;
  hasHubspot: boolean;
};

const getRoutes: (params: GetRoutesParams) => Routes = ({ defaultRedirectUrl, permissions, isStaff, hasHubspot }) => {
  const canViewUsers = permissions?.has('can_view_profiles');
  const canViewOrders = permissions?.has('can_view_orders');

  return [
    {
      exact: true,
      path: '/404',
      component: NotFoundView,
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/login',
      component: LoginView,
    },
    {
      exact: true,
      guard: GuestGuard,
      path: '/signup',
      component: SignUpView,
    },
    {
      exact: true,
      path: urls.verificationStatus.url(),
      component: VerificationStatusPage,
    },
    {
      exact: true,
      path: urls.verification.url(),
      component: VerificationPage,
    },
    {
      path: '/app',
      guard: AuthGuard,
      routes: [
        {
          exact: true,
          path: '/app',
          layout: TwoPaneLayout,
          component: () => <Redirect to={defaultRedirectUrl} />,
        },

        /**
         * Home menu
         */
        {
          exact: true,
          path: urls.home.url(),
          layout: TwoPaneLayout,
          component: () => <Redirect to={urls.reporting.url()} />,
        },

        {
          exact: true,
          path: urls.reporting.url(),
          layout: TwoPaneLayout,
          component: HomePage,
        },

        {
          exact: true,
          path: urls.usage.url(),
          layout: TwoPaneLayout,
          component: UsageView,
          guard: PermissionGuard,
          permission: urls.usage.permission,
        },

        {
          exact: true,
          path: urls.performance.url(),
          layout: TwoPaneLayout,
          component: PerformanceReportsPage,
          guard: PermissionGuard,
          permission: urls.performance.permission,
        },

        {
          exact: true,
          path: urls.managers.url(),
          layout: TwoPaneLayout,
          component: TeamPerformancePage,
          guard: PermissionGuard,
          permission: urls.managers.permission,
        },

        /**
         * Emulator menu
         */
        {
          exact: true,
          path: urls.emulator.url(),
          component: EmulatorPage,
          guard: PermissionGuard,
          permission: urls.emulator.permission,
          layout: FullScreenLayout,
        },

        /**
         * Usage menu
         */
        {
          exact: true,
          path: urls.activity.url(),
          layout: TwoPaneLayout,
          component: () =>
            canViewUsers ? (
              <Redirect to={urls.users.url()} />
            ) : canViewOrders ? (
              <Redirect to={urls.orders.url()} />
            ) : (
              <Redirect to={urls.coverage.url()} />
            ),
        },

        {
          exact: true,
          path: urls.allUsers.url(),
          layout: TwoPaneLayout,
          component: AllUsersPage,
          guard: PermissionGuard,
          permission: urls.allUsers.permission,
        },

        {
          exact: true,
          path: urls.userInformation.url(),
          layout: TwoPaneLayout,
          component: UserInformationPage,
          guard: PermissionGuard,
          permission: urls.userInformation.permission,
        },

        {
          exact: true,
          path: urls.users.url(),
          layout: TwoPaneLayout,
          component: UsersPage,
          guard: PermissionGuard,
          permission: urls.users.permission,
        },

        {
          exact: true,
          path: urls.user.url(),
          layout: TwoPaneLayout,
          component: UserDetailsPage,
          guard: PermissionGuard,
          permission: urls.user.permission,
        },

        {
          exact: true,
          path: urls.orders.url(),
          layout: TwoPaneLayout,
          component: OrdersPage,
          guard: PermissionGuard,
          permission: urls.orders.permission,
        },

        {
          exact: true,
          path: urls.orderInformation.url(),
          layout: TwoPaneLayout,
          component: OrderInformation,
          guard: PermissionGuard,
          permission: urls.orderInformation.permission,
        },

        {
          exact: true,
          path: urls.orderCreate.url(),
          layout: FullScreenLayout,
          component: CreateOrderPage,
          guard: PermissionGuard,
          permission: urls.orderCreate.permission,
        },

        {
          exact: true,
          path: urls.ordersUpload.url(),
          layout: FullScreenLayout,
          component: BatchOrdersPage,
          guard: PermissionGuard,
          permission: urls.ordersUpload.permission,
        },

        {
          exact: true,
          path: urls.tasks.url(),
          layout: TwoPaneLayout,
          component: TasksPage,
          guard: PermissionGuard,
          permission: urls.tasks.permission,
        },

        {
          exact: true,
          path: urls.tasks.parameterUrl?.(),
          layout: TwoPaneLayout,
          component: TasksPage,
          guard: PermissionGuard,
          permission: urls.tasks.permission,
        },

        {
          exact: true,
          path: urls.coverage.url(),
          layout: TwoPaneLayout,
          component: CoveragePage,
          guard: PermissionGuard,
          permission: urls.coverage.permission,
        },

        /**
         * Development menu
         */
        {
          exact: true,
          path: urls.development.url(),
          layout: TwoPaneLayout,
          component: () => <Redirect to={urls.keys.url()} />,
          guard: PermissionGuard,
          permission: urls.development.permission,
        },

        {
          exact: true,
          path: urls.keys.url(),
          layout: TwoPaneLayout,
          component: KeysPage,
          guard: PermissionGuard,
          permission: urls.keys.permission,
        },

        {
          exact: true,
          path: urls.generateKeys.url(),
          layout: FullScreenLayout,
          component: GenerateKeysPage,
          guard: PermissionGuard,
          permission: urls.keys.permission,
        },

        {
          exact: true,
          path: urls.platformKeys.url(),
          layout: FullScreenLayout,
          component: ExternalIntegrationPage,
          guard: PermissionGuard,
          permission: urls.platforms.permission,
        },

        {
          exact: true,
          path: urls.platforms.url(),
          layout: TwoPaneLayout,
          component: PlatformsPage,
          guard: PermissionGuard,
          permission: urls.platforms.permission,
        },

        {
          exact: true,
          path: urls.logs.url(),
          layout: TwoPaneLayout,
          component: LogsPage,
          guard: PermissionGuard,
          permission: urls.logs.permission,
        },

        {
          exact: true,
          path: urls.webhooks.url(),
          layout: TwoPaneLayout,
          component: WebhooksView,
          guard: PermissionGuard,
          permission: urls.webhooks.permission,
        },

        {
          exact: true,
          path: urls.createWebhook.url(),
          layout: FullScreenLayout,
          component: WebhookView,
          guard: PermissionGuard,
          permission: urls.createWebhook.permission,
        },

        {
          exact: true,
          path: urls.editWebhook.url(),
          layout: FullScreenLayout,
          component: WebhookView,
          guard: PermissionGuard,
          permission: urls.editWebhook.permission,
        },

        {
          exact: true,
          path: urls.systemStatus.url(),
          layout: TwoPaneLayout,
          component: SystemStatusPage,
          guard: PermissionGuard,
          permission: urls.systemStatus.permission,
        },

        /**
         * Customization menu
         */
        {
          exact: true,
          path: urls.customization.url(),
          layout: TwoPaneLayout,
          component: () => <Redirect to={urls.branding.url()} />,
          guard: PermissionGuard,
          permission: urls.customization.permission,
        },

        {
          exact: true,
          path: urls.marketplace.url(),
          layout: TwoPaneLayout,
          component: () => (isStaff ? <MarketplacePage /> : <Redirect to={urls.branding.url()} />),
          guard: PermissionGuard,
          permission: urls.marketplace.permission,
        },

        {
          exact: true,
          path: urls.externalIntegration.url(),
          layout: FullScreenLayout,
          component: () => (isStaff ? <ExternalIntegrationPage /> : <Redirect to={urls.branding.url()} />),
          guard: PermissionGuard,
          permission: urls.externalIntegration.permission,
        },

        {
          exact: true,
          path: urls.account.url(),
          layout: TwoPaneLayout,
          component: () => <Redirect to={urls.branding.url()} />,
          guard: PermissionGuard,
          permission: urls.customization.permission,
        },

        {
          exact: true,
          path: urls.branding.url(),
          layout: TwoPaneLayout,
          component: BrandingPage,
          guard: PermissionGuard,
          permission: urls.customization.permission,
        },

        {
          exact: true,
          path: urls.customSettings.url(),
          layout: TwoPaneLayout,
          component: CustomSettingsPage,
          guard: PermissionGuard,
          permission: urls.customSettings.permission,
        },

        {
          exact: true,
          path: urls.twilioIntegration.url(),
          layout: FullScreenLayout,
          component: TwilioIntegrationPage,
          guard: PermissionGuard,
          permission: urls.twilioIntegration.permission,
        },

        {
          exact: true,
          path: urls.templateCreate.url(),
          layout: FullScreenLayout,
          component: TemplateCreatePage,
          guard: PermissionGuard,
          permission: urls.templateCreate.permission,
        },

        {
          exact: true,
          path: urls.templateEdit.url(),
          layout: FullScreenLayout,
          component: TemplateEditPage,
          guard: PermissionGuard,
          permission: urls.templateEdit.permission,
        },

        {
          exact: true,
          path: urls.templates.url(),
          layout: TwoPaneLayout,
          component: TemplatesPage,
          guard: PermissionGuard,
          permission: urls.templates.permission,
        },

        {
          exact: true,
          path: urls.fieldsCustomization.url(),
          layout: TwoPaneLayout,
          component: FieldsCustomizationPage,
          guard: PermissionGuard,
          permission: urls.fieldsCustomization.permission,
        },

        /**
         * Help menu
         */
        {
          exact: true,
          path: urls.supportTickets.url(),
          layout: TwoPaneLayout,
          guard: PermissionGuard,
          permission: urls.supportTickets.permission,
          component: SupportTicketsPage,
        },
        {
          exact: true,
          path: urls.createSupportTicket.url(),
          guard: PermissionGuard,
          permission: urls.supportTickets.permission,
          component: () => (hasHubspot ? <Redirect to={urls.supportTickets.url()} /> : <SupportTicketCreatePage />),
        },
        {
          exact: true,
          path: urls.supportSelectCategory.url(),
          guard: PermissionGuard,
          permission: urls.supportTickets.permission,
          component: SupportTicketCreatePageSelectCategory,
        },
        {
          exact: true,
          path: urls.supportFillDetails.url(),
          guard: PermissionGuard,
          permission: urls.supportTickets.permission,
          component: SupportTicketCreatePageFillDetails,
        },
        {
          exact: true,
          path: urls.ticketInformation.url(),
          layout: TwoPaneLayout,
          guard: PermissionGuard,
          permission: urls.ticketInformation.permission,
          component: TicketInformationPage,
        },

        /**
         * Settings menu
         */
        {
          exact: true,
          path: urls.settings.url(),
          layout: TwoPaneLayout,
          component: () => {
            if (urls.company.permission && permissions?.has(urls.company.permission)) {
              return <Redirect to={urls.company.url()} />;
            }

            if (urls.team.permission && permissions?.has(urls.team.permission)) {
              return <Redirect to={generatePath(urls.team.url(), { tab: TeamTab.All })} />;
            }

            return <Redirect to={urls.profile.url()} />;
          },
          guard: PermissionGuard,
          permission: urls.settings.permission,
        },

        {
          exact: true,
          path: urls.company.url(),
          layout: TwoPaneLayout,
          component: CompanyPage,
          guard: PermissionGuard,
          permission: urls.company.permission,
        },

        {
          exact: true,
          path: urls.addMember.url(),
          layout: FullScreenLayout,
          component: AddMemberPage,
          guard: PermissionGuard,
          permission: urls.addMember.permission,
        },

        {
          exact: true,
          path: urls.editMember.url(),
          layout: FullScreenLayout,
          component: EditMemberPage,
          guard: PermissionGuard,
          permission: urls.editMember.permission,
        },

        {
          exact: true,
          path: urls.team.url(),
          layout: TwoPaneLayout,
          component: TeamPage,
          guard: PermissionGuard,
          permission: urls.team.permission,
        },

        {
          exact: true,
          path: urls.profile.url(),
          layout: TwoPaneLayout,
          component: ProfileView,
          guard: PermissionGuard,
          permission: urls.profile.permission,
        },

        /**
         * Redirects for old URLs
         */
        ...Object.values(urls)
          .filter((item) => Boolean(item.oldUrl))
          .map<Routes[number]>((item) => ({
            exact: true,
            path: item.oldUrl,
            component: () => {
              const url = item.url();

              if (url.startsWith('http')) {
                window.location.href = url;

                return null;
              }

              return <Redirect to={url} />;
            },
          })),

        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
    {
      path: '*',
      routes: [
        {
          exact: true,
          guard: AuthGuard,
          path: '/',
          component: () => <Redirect to={defaultRedirectUrl} />,
        },
        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
  ];
};

export function RoutesWrapper() {
  const { user } = useAuth();

  const profileSettings = user?.profile;
  const role = profileSettings?.role.id;
  const isStaff = !!profileSettings?.staff_dashboard_url;

  const { permissions } = useAuth();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const hasHubspot = useHasHubspotFeature();
  const defaultRedirectUrl = (() => {
    switch (true) {
      case role === 'orders_manager' && !user?.isCompanyIndividual:
        return urls.orders.url();
      case user?.is_target_account && user?.properties && !user.properties.targetVisited:
        return urls.coverage.url();
      case params.has('backUrl'):
        return params.get('backUrl') || urls.home.url();
      default:
        return urls.home.url();
    }
  })();

  const routes = getRoutes({ defaultRedirectUrl, permissions, isStaff, hasHubspot });

  return <RenderRoutes defaultRedirectUrl={defaultRedirectUrl} routes={routes} />;
}
