import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { apiClient } from 'api/ApiClient';
import { useGetProfile } from 'entities/user/api/useGetProfile';
import type { PromoConfig } from 'hooks/promo';

export type FeaturesResponse = {
  promo?: PromoConfig[];
  flags?: {
    /**
     * shows sandbox switch for individual companies
     */
    sandbox_switch_individual_company?: boolean;

    /**
     * collects fullstories with custom rate when it comes to individual companies
     */
    paygo_fullstory_sample_rate?: number;

    /**
     * shows old users (users route) -- will be dropped as soon as Creative Services finish migration (Apr 2025)
     */
    old_users_companies?: string[];

    /**
     * shows Hubspot chat, surveys: link in the Support tab leads to an external page
     * disables Zendesk chat; Delighted surveys
     */
    hubspot?:
      | {
          id: string;
          email: string; // email is not used anywhere, it's here only for more transparent id handling in Django
        }[]
      | true;
  };
};

export const useFeatures = (enabled = true) => {
  return useQuery({
    queryKey: ['getFeatures'],
    queryFn: () => apiClient.getAnalyticsSource<FeaturesResponse>('features', {}),
    retry: false,
    enabled,
  });
};

export const useHasAllUsersFeature = () => {
  const { data: features } = useFeatures();
  const { data: profile } = useGetProfile();

  return !new Set(features?.flags?.old_users_companies).has(profile?.company?.id ?? '');
};

export const useHasHubspotFeature = () => {
  const { data: features } = useFeatures();
  const { data: profile } = useGetProfile();

  const isStaff = !!profile?.staff_dashboard_url;
  const hubspot = features?.flags?.hubspot;
  const hubspotIds = useMemo(() => (Array.isArray(hubspot) ? hubspot.map((company) => company.id) : []), [hubspot]);

  return isStaff || hubspot === true || new Set(hubspotIds).has(profile?.company?.id ?? '');
};
