import type { FC } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ThemeProvider as StylesThemeProvider } from '@mui/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserHistory } from 'history';
import { ModalProvider } from 'react-modal-hook';
import { Router } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import { ImpersonationBar } from 'layouts/2Pane/ImpersonationBar';
import { AuthProvider } from 'shared/contexts/Auth0Context';
import { FiltersContextProvider } from 'shared/contexts/FiltersContext';
import { SandboxContextProvider } from 'shared/contexts/SandboxContext';
import { theme } from 'theme';
import { ThemedSnackbarProvider } from 'theme/ThemedSnackbarProvider';
import { ReactErrorView } from 'views/errors/ReactErrorView';

import { Analytics } from './integrations/Analytics';
import { DelightedSurvey } from './integrations/DelightedSurvey';
import { HubspotChat } from './integrations/HubspotChat';
import { queryClient } from './integrations/queryClient';
import { ScrollReset } from './integrations/ScrollReset';
import './integrations/sentry';
import { ZendeskChat } from './integrations/ZendeskChat';
import { RoutesWrapper } from './routes/RoutesWrapper';
import { GlobalStyles } from './styles/GlobalStyles';

const history = createBrowserHistory();

const App: FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <StylesThemeProvider theme={theme}>
        <Router history={history}>
          <Sentry.ErrorBoundary fallback={({ resetError }) => <ReactErrorView onReset={resetError} />}>
            <QueryClientProvider client={queryClient}>
              {!import.meta.env.PROD && <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ThemedSnackbarProvider>
                  <Auth0Provider
                    authorizationParams={{
                      redirect_uri: window.location.origin,
                    }}
                    clientId={import.meta.env.VITE_APP_AUTH0_CLIENT_ID ?? ''}
                    domain={import.meta.env.VITE_APP_AUTH0_DOMAIN ?? ''}
                    onRedirectCallback={(appState) => {
                      history.replace(appState?.backUrl || window.location.origin);
                    }}
                  >
                    <AuthProvider>
                      <ImpersonationBar />
                      <SandboxContextProvider>
                        <ModalProvider rootComponent={TransitionGroup}>
                          <FiltersContextProvider>
                            <GlobalStyles />
                            <ScrollReset />
                            <ZendeskChat />
                            <HubspotChat />
                            <DelightedSurvey />
                            <Analytics history={history} />
                            <RoutesWrapper />
                          </FiltersContextProvider>
                        </ModalProvider>
                      </SandboxContextProvider>
                    </AuthProvider>
                  </Auth0Provider>
                </ThemedSnackbarProvider>
              </LocalizationProvider>
            </QueryClientProvider>
          </Sentry.ErrorBoundary>
        </Router>
      </StylesThemeProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
