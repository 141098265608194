import type { FC } from 'react';
import { useRef } from 'react';

import { Helmet } from 'react-helmet';

import { useFeatures, useHasHubspotFeature } from 'hooks/features';
import { useAuth } from 'shared/lib/auth/useAuth';

const ZE_SCRIPT_TAG_ID = 'ze-snippet';

export const ZendeskChat: FC = () => {
  const isLoadListenerAdded = useRef<boolean>(false);
  const { user, isAuthenticated } = useAuth();
  const { isLoading } = useFeatures(isAuthenticated);
  const hasHubspot = useHasHubspotFeature();

  if (!isAuthenticated || !import.meta.env.VITE_APP_ZENDESK_KEY || isLoading || hasHubspot) {
    return null;
  }

  return (
    <Helmet
      onChangeClientState={() => {
        const scriptTag = document.getElementById(ZE_SCRIPT_TAG_ID);

        if (user && scriptTag && !isLoadListenerAdded.current) {
          isLoadListenerAdded.current = true;

          scriptTag.addEventListener('load', () => {
            window.zE?.(function () {
              window.zE?.identify?.({
                name: `${user.profile?.first_name ? `${user.profile.first_name} ` : ''}${
                  user.profile?.last_name || ''
                }`,
                email: user.email,
              });
            });
          });
        }
      }}
    >
      <script
        id={ZE_SCRIPT_TAG_ID}
        src={`https://static.zdassets.com/ekr/snippet.js?key=${import.meta.env.VITE_APP_ZENDESK_KEY}`}
      />
    </Helmet>
  );
};
