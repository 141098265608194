import type { FC } from 'react';
import { useEffect } from 'react';

import { Helmet } from 'react-helmet';

import { useFeatures, useHasHubspotFeature } from 'hooks/features';
import { useAuth } from 'shared/lib/auth/useAuth';

export const HubspotChat: FC = () => {
  const { isAuthenticated, user } = useAuth();
  const { isLoading } = useFeatures(isAuthenticated);
  const hasHubspot = useHasHubspotFeature();

  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationToken: user?.hubspot_identification_token,
      identificationEmail: user?.email,
    };

    window.hsConversationsOnReady = [];
    window.hsConversationsOnReady.push(() => {
      if (user && !user?.hubspot_identification_token) {
        console.error(
          'No hubspot identification token from the profile. User is going to be anonymous in Hubspot live chat',
        );
      }

      window.HubSpotConversations?.widget.load();
    });
  }, [user]);

  if (!isAuthenticated || !import.meta.env.VITE_APP_HUBSPOT_ID || isLoading || !hasHubspot) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        defer
        id="hs-script-loader"
        src={`https://js.hs-scripts.com/${import.meta.env.VITE_APP_HUBSPOT_ID}.js`}
      />
    </Helmet>
  );
};
